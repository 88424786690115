import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import AnimatedNumber from "animated-number-react";
import { FiRefreshCw } from "react-icons/fi";
import { BsChevronCompactDown } from "react-icons/bs";
import {
  AiOutlineLoading3Quarters,
  AiFillStar,
  AiOutlineStar,
} from "react-icons/ai";
import axios, { Axios } from "axios";

const formatValue = (value) => `${separator(Number(value).toFixed(0))}`;
function separator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function ACard({ cardName, _id, _num, _maxQuant }) {
  return (
    <div className="drop-shadow-lg col-span-1 ">
      <div className="item-bg border-0 rounded-t-lg shadow-lg drop-shadow-sm w-full">
        <div className="text-xs text-yellow-400 flex flex-row justify-end m-1 mt-2">
          {_id > 4 ? <AiOutlineStar /> : <AiFillStar />}
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <img
          width="120px"
          height="120px"
          className="cardLeft transition-all duration-500  hover:scale-150 "
          src={`https://nft-assets.crypterium.game/items/i/` + _id}
        />
      </div>

      <div className="flex flex-col w-full justify-center text-center p-1 border-0 rounded-b-lg bg-white text-black text-xl font-bold">
        <span className="text-xs text-yellow-500">{cardName}</span>
        <span className="flex flex-row justify-center">
          <AnimatedNumber
            value={_num}
            formatValue={formatValue}
            duration="500"
          />
          /{_maxQuant}
        </span>
      </div>
    </div>
  );
}

function RewardCountAPI({}) {
  const [idsArray, setIdsArray] = useState([]);
  const [itemLeft, setItemLeft] = useState([]);
  const wc = useSelector((state) => state.walletConnect.value);

  useEffect(() => {
    callItemLeft();
    return () => {
      setItemLeft([]);
    };
  }, []);

  async function callItemLeft() {
    let _ItemArr = [];
    axios
      .get(
        "https://snybclbtdhxo.grandmoralis.com:2053/server/functions/fetchRemainingCards"
      )
      .then((x) => {
        const result = x.data.result;
        result.forEach((element) => {
          _ItemArr.push(element);
        });
      })
      .then(() => {
        setItemLeft(_ItemArr);
      });
  }

  return (
    <>
      {
        <div className="-mt-9 w-full h-[520px] md:h-[300px] md:w-5/6 p-5 py-9 flex flex-col justify-evenly items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl transition-all">
          <div className=" text-black text-2xl font-bold my-2">
            Special Card Amount
          </div>
          <div className="grid grid-flow-row grid-cols-3 md:grid-cols-6 gap-1 md:gap-4">
            {itemLeft.slice(0, 6).map((x, i) => (
              <ACard
                cardName={x.name}
                key={i}
                _id={i + 1}
                _num={x.remaining}
                _maxQuant={x.maxQuantity}
              />
            ))}
          </div>
        </div>
      }
    </>
  );
}

export default RewardCountAPI;
