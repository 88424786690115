import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import ProgressBar from '@ramonak/react-progress-bar'
import { FaChevronRight, FaChevronDown } from 'react-icons/fa'
import { TiLockClosedOutline, TiLockOpenOutline } from 'react-icons/ti'
import Countdown from 'react-countdown'

function InProgress({ title, completed, maxCompleted, lock, baseBgColor }) {
    return (
        <div className="relative">
            {!lock ? (
                <div>
                    {title}
                    <ProgressBar
                        completed={completed}
                        bgColor="#0f63ff"
                        height="9px"
                        borderRadius="0"
                        isLabelVisible={false}
                        labelColor="#e80909"
                        baseBgColor={baseBgColor}
                        labelSize="8"
                        maxCompleted={maxCompleted}
                        customLabel=""
                    />
                </div>
            ) : (
                <div className="text-gray-400">
                    {title}
                    <TiLockClosedOutline
                        fontSize={28}
                        className="absolute left-1/2 top-1/4"
                    />
                </div>
            )}
        </div>
    )
}

function TimeLine({ period, startTime, neverClaimed, tokens }) {
    const [showModal, setShowModal] = useState(false)
    const currentTimeStamp = Math.floor(Date.now() / 1000)
    let diffTime = currentTimeStamp - startTime
    //console.log('neverClaimed:', neverClaimed, tokens)
    let bar1, bar2, bar3
    let isFinish = false

    if (neverClaimed) {
        if (diffTime / period >= 1) {
            bar1 = period

            if (diffTime / period >= 2) {
                bar2 = period

                if (tokens == 15000) {
                    if (diffTime / period >= 4) {
                        bar3 = period * 2
                        isFinish = true
                    } else {
                        bar3 = diffTime - period * 2
                    }
                } else {
                    bar3 = 0
                    isFinish = false
                }
            } else {
                bar2 = diffTime - period
                bar3 = 0
            }
        } else {
            bar1 = diffTime
            bar2 = 0
            bar3 = 0
        }
    } else {
        if (diffTime / period >= 1) {
            bar2 = period

            if (diffTime / period >= 3) {
                bar3 = period * 2
                isFinish = true
            } else {
                bar3 = diffTime - period / 2
                isFinish = false
            }
        } else {
            bar2 = diffTime
            bar3 = 0
        }
    }
    let baseBgColor
    tokens == 15000 || tokens == 5000
        ? (baseBgColor = '#aaaaaa')
        : (baseBgColor = '#d4d4d4')

    return (
        <div className="w-full flex flex-col items-center border-0 p-3 text-black transition-all -mt-9 mb-3 drop-shadow-md">
            <div className="w-full grid grid-flow-row grid-cols-9 gap-0 mt-1">
                <div className="relative col-span-1 w-1/12  border-0 h-[9px] z-50  bg-[#0f63ff] rounded-l-xl place-self-end scale-75">
                    <div
                        className={`absolute -top-3 -left-4 text-white col-span-1 z-20 p-2 w-8 border-0  rounded-full font-bold text-xs  self-end text-center overflow-hidden scale-105 transition-all ${
                            tokens == 5000 ? ' bg-[#d4d4d4]' : ' bg-[#0f63ff]'
                        }`}
                    >
                        0
                    </div>
                </div>
                <div className="relative col-span-2 border-0 scale-y-75">
                    <InProgress
                        completed={tokens == 5000 ? 0 : bar1}
                        maxCompleted={period}
                        lock={false}
                        baseBgColor={tokens == 5000 ? '#d4d4d4' : '#aaaaaa'}
                    />
                </div>
                <div className="relative col-span-2 border-0 scale-y-75">
                    <InProgress
                        completed={bar2}
                        maxCompleted={period}
                        lock={false}
                        baseBgColor={'#aaaaaa'}
                    />
                    <div
                        className={`absolute -top-3 -left-5 text-white col-span-1 scale-x-75 z-50 p-2 w-8 border-0 bg-white rounded-full font-bold text-xs self-end text-center overflow-hidden scale-105 transition-all ${
                            bar1 == period || tokens == 5000
                                ? 'bg-[#0f63ff]'
                                : 'bg-[#aaaaaa]'
                        }`}
                    >
                        15
                    </div>
                </div>
                <div className="relative col-span-3 border-0 scale-y-75">
                    <InProgress
                        completed={bar3}
                        maxCompleted={period * 2}
                        lock={false}
                        baseBgColor={baseBgColor}
                    />
                    <div
                        className={`absolute -top-3 -left-3 text-white col-span-1 scale-x-75  p-2 w-8 border-0 bg-white rounded-full font-bold text-xs  self-end text-center overflow-hidden scale-105  transition-all ${
                            bar2 == period ? 'bg-[#0f63ff]' : 'bg-[#aaaaaa]'
                        }`}
                    >
                        30
                    </div>
                </div>
                <div
                    className={`relative col-span-1 w-1/12 border-0 h-[9px] rounded-r-xl scale-75 ${
                        tokens == 15000 || tokens == 5000
                            ? isFinish
                                ? 'bg-[#0f63ff]'
                                : 'bg-[#aaaaaa]'
                            : 'bg-[#d4d4d4]'
                    } `}
                >
                    <div
                        className={`absolute -top-3 -left-1 text-white col-span-1 p-2 w-8 border-0 rounded-full font-bold text-xs self-end text-center overflow-hidden scale-105 transition-all ${
                            tokens == 15000 || tokens == 5000
                                ? isFinish
                                    ? 'bg-[#0f63ff]'
                                    : 'bg-[#aaaaaa]'
                                : 'bg-[#d4d4d4]'
                        } `}
                    >
                        60
                    </div>
                </div>
            </div>
            {/*}
            <div className="w-11/12 grid grid-flow-row grid-cols-8 gap-0 justify-items-center mt-3">
                <div className="col-span-1 p-0 w-full border-0 bg-white rounded-md text-xs scale-75 origin-bottom self-end text-center overflow-visible hover:scale-105 transition-all">
                    Breeding
                </div>
                <div className="col-span-1 border-0"></div>
                <div className="col-span-1 p-0 w-full border-0 bg-white rounded-md text-xs scale-75 origin-bottom self-end text-center overflow-visible hover:scale-105 transition-all">
                    Pre-Incubate
                </div>
                <div className="col-span-1 border-0"></div>
                <div className="col-span-1 p-0 w-full border-0 bg-white rounded-md text-xs scale-75 origin-bottom self-end text-center overflow-visible hover:scale-105 transition-all">
                    {tokens == 15000 || tokens == 5000
                        ? 'Incubate'
                        : 'Stop Incubate'}
                </div>
                <div className="col-span-2 border-0"></div>
                <div className="col-span-1 p-0 w-full border-0 bg-white rounded-md text-xs scale-75 origin-bottom self-end text-center overflow-visible hover:scale-105 transition-all">
                    Hatched Finish
                </div>
            </div>*/}
        </div>
    )
}

export default TimeLine
