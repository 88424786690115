import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import AnimatedNumber from "animated-number-react";
import Backdrop from "@mui/material/Backdrop";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { RiWallet3Line } from "react-icons/ri";
import { FiRefreshCw } from "react-icons/fi";
import { FaWindowClose, FaCheck } from "react-icons/fa";
import html2canvas from "html2canvas";
import AddNetwork from "./AddNetwork";
import { AiOutlineCaretLeft } from "react-icons/ai";
import axios from "axios";
import RewardCount from "./RewardCount";
import { GiAlarmClock } from "react-icons/gi";
import { GrFormViewHide, GrFormView } from "react-icons/gr";
import { BsFillEyeFill } from "react-icons/bs";
import TimeLineStake from "./TimelineStake";
import Countdown from "react-countdown";
import js_ago from "js-ago";
/*
const erc1155Stake = '0x92053fc7aCBb30181eA67EE136ED71119640f102' // BSC Testnet Stake Addr
const CCOIN = '0xbFB0be698655eCdEb6dDe80831dfb3C6553c4C6D' //BSC Testnet
const CCSX = '0x0D82Bdd0Db436dBc6473B58f0B55d9854cAE3F87' //BSC Testnet
const BUSD = '0x32ed57673EC8a0c6e5c4cd0c53e2d0a5be1497f9' //busd testnet


const erc1155Stake = '0x4a2EF456014c3eFB798F217428764F933834F8CC' //BSC Mainnet
const CCOIN = '0xc209831f7349D4E200d420326B3401899Ab9Ae68' //BSC Mainnet
const CCSX = '0x1819615B0643c54A6096af42a72C761f85E7ec37' //BSC Mainnet
const BUSD = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56' //busd Mainnet
*/
const erc1155Stake = process.env.REACT_APP_NFT1155STAKE_CONTRACT;
const CCOIN = process.env.REACT_APP_CCOIN;
const CCSX = process.env.REACT_APP_CCSX;
const BUSD = process.env.REACT_APP_BUSD;

const IERC20 = require("../abi/IERC20");
const rcAbi = require("../abi/rcAbi");
const abiStake = require("../abi/abiStake");
const formatValue = (value) => `${separator(Number(value).toFixed(0))}`;
function separator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const isMetaMaskInstalled = () => {
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};

function NFTCard({ id }) {
  const [nftName, setNftName] = useState();
  async function getMetaData() {
    await axios
      .get("https://nft-assets.crypterium.game/items/j/" + id)
      .then((x) => {
        //console.log('x:', x.data.name)
        setNftName(x.data.name);
      })
      .catch((err) => console.log("err:", err));
  }
  getMetaData();
  return (
    <div className="card col-span-1 flex flex-col">
      <img
        className="absolute scale-125 hover:scale-150 transition-all duration-1000 origin-top"
        src={`https://nft-assets.crypterium.game/items/i/` + id}
      />
      <div className="absolute -bottom-0 text-center  bg-black/60 p-2 text-gray-200 w-full transition-all duration-200 z-50">
        {nftName}
        <br />
      </div>
    </div>
  );
}

function MiniNFTCard({ id }) {
  return (
    <div className="border-0 my-4 scale-125 hover:scale-150 hover:z-40 transition-all duration-500">
      <img
        className=" transition-all duration-1000 "
        src={`https://nft-assets.crypterium.game/items/i/` + id}
      />
    </div>
  );
}

function MyStake() {
  const [error, setError] = useState();
  const [info, setInfo] = useState();
  const [account, setAccount] = useState();
  const [state, setState] = useState(0);
  const [openClaimStake, setOpenClaimStake] = useState(false);
  const [openThePrize, setOpenThePrize] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [hiddenClaim, setHiddenClaim] = useState(true);
  const [numBox, setNumBox] = useState(1);
  const [nfts, setNfts] = useState([]);
  const [stakeLength, setStakeLength] = useState();
  const [period, setPeriod] = useState(0);
  const [stakeArr, setStakeArr] = useState([]);
  const [currentTime, setCurrentTime] = useState();
  const [refresh, setRefresh] = useState(false);

  const wc = useSelector((state) => state.walletConnect.value);

  let provider;
  let ethereum = window.ethereum;
  let tempSigner;

  if (!!wc.account) {
    ethereum = wc.provider;
    provider = wc.web3Provider;
    tempSigner = provider.getSigner();
    ethereum.on("accountsChanged", (accounts) => setState(state));
  } else if (isMetaMaskInstalled()) {
    ethereum = window.ethereum;
    provider = new ethers.providers.Web3Provider(ethereum);
    tempSigner = provider.getSigner();
    ethereum.on("accountsChanged", (accounts) => setState(state));
  }

  useEffect(() => {
    if (!!wc.account || isMetaMaskInstalled()) {
      console.clear();
      getCurrentAccount();
      setPeriod(getPeriod());
      setStakeLength(getStakeLength());
      setStakeArr(getStakeList());
      setCurrentTime(getBlockTimeStamp());
      console.log("account:", account);
    }
    return () => {
      //setStakeArr()
    };
    //}, [account])
  }, [wc.account, account, refresh]);

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    window.location.href.includes("ref")
      ? localStorage.setItem("ref", window.location.href.split("ref=")[1])
      : localStorage.setItem("ref", "cf000000000");

    return () => {};
  }, []);

  //// GET Require error from contract using catch promist ////
  async function reason(_hash) {
    let hash = _hash;
    let tx = await provider.getTransaction(hash);
    if (!tx) {
      console.log("Reason : tx not found");
    } else {
      let code = await provider.call(tx, tx.blockNumber);
      return ethers.utils.toUtf8String(code.substr(138));
    }
  }

  const contract = new ethers.Contract(erc1155Stake, abiStake, tempSigner);

  async function getPeriod() {
    try {
      const _period = await contract.callStatic.timePeriod();
      //console.log('period:', _period.toNumber())
      setPeriod(_period.toNumber());
      return _period.toNumber();
    } catch (err) {
      console.log("err: ", err);
    }
  }

  async function getStakeLength() {
    try {
      const _stakeLength = await contract.callStatic.stakeLength(account);
      //console.log('_stakeLength:', _stakeLength.toNumber())
      return _stakeLength.toNumber();
    } catch (err) {
      console.log("err: ", err);
    }
  }
  //getStake(account, 3)
  async function getStake(_account, _num) {
    try {
      const _stake = await contract.callStatic.stake(_account, _num);
      //console.log('_stake:', _stake)
      return _stake;
    } catch (err) {
      console.log("Error : ", err.message, ", Code : ", err.code);
    }
  }

  async function getStakeList() {
    let _stakeArr = [];
    let _stakeIndex = {};
    for (let i = 0; i < (await getStakeLength()); i++) {
      let _nftIdToArr = [];
      await getStake(account, i).then((result) => {
        //console.log('result:', result)
        result[0].map((x, i) => {
          for (let j = 0; j < result[1][i]; j++) {
            _nftIdToArr.push(parseInt(x));
          }
        });

        _stakeIndex = {
          nftId: _nftIdToArr,
          startTime: parseInt(result[2]),
          claimTime: parseInt(result[3]),
          isClaimed: result[4] == true,
        };
        _stakeArr.push(_stakeIndex);
      });
    }
    //console.log('_stakeArr:', _stakeArr)
    setStakeArr(_stakeArr);
    return _stakeArr;
  }

  async function claimStake(_index) {
    try {
      const tx = await contract.claimStake(_index);
      setInfo("Waiting for block Confirmation");
      const uB = await tx.wait(1).then((result) => {
        setInfo("Transaction complete");
        setState(3);
      });
    } catch (err) {
      await reason(err.transaction.hash).catch((x) => {
        console.log("REASONY:", x.data.message);
        x.data.message == "out of gas"
          ? setError("Trasaction Error : Out of gas")
          : setError(x.data.message);
      });
      //let reason = ethers.utils.toUtf8String('0x' + code.substr(138))
      //console.log('revert reason:', reason)
      setInfo("");
      setState(6);
    }
  }

  async function getBlockTimeStamp() {
    const blockTimeStamp = await contract.callStatic.getBlockTimeStamp();
    setCurrentTime(parseInt(blockTimeStamp));
    console.log("blockTimeStamp", parseInt(blockTimeStamp));
    return parseInt(blockTimeStamp);
  }

  let referal = localStorage.getItem("ref");

  async function getCurrentAccount() {
    const accounts = await ethereum.request({
      method: "eth_accounts",
    });
    setAccount(accounts[0]);
    return accounts[0];
  }

  function viewNFTSet(_nftArr) {
    setNfts(_nftArr);
    if (nfts.length == 0) {
      if (localStorage.getItem("lastPurchase") == null) {
        console.log("No Last Purchagse Data");
        return;
      } else {
        _nftArr = JSON.parse(localStorage.getItem("lastPurchase"));
        setNfts(_nftArr);
      }
    }
    setOpenClaimStake(false);
    setOpenThePrize(true);
    setShowItems(false);
    setState(1);
    setError("");
    setNumBox(3);
  }
  //console.log('period:', period)

  //////////////////////////////////////// START STAKE COMPONENT ////////////////////////////////////////
  function Stake({ i, nfts, startTime, claimTime, isClaimed, period }) {
    const completeTime = (startTime + period * 4) * 1000;
    /*
        console.log('completeTime:', completeTime - currentTime)
        console.log('claimTime:', claimTime)
        console.log('isClaimed', i, ':', isClaimed)
        console.log('currentTime:', currentTime)
        console.log('startTime:', startTime)
        */
    function profitCalculation() {
      const _timeAgo = (isClaimed ? claimTime : currentTime) - startTime;

      if (_timeAgo > period * 2 && _timeAgo < period * 3) {
        return nfts.length * 200;
      } else if (_timeAgo >= period * 3 && _timeAgo < period * 4) {
        return nfts.length * 600;
      } else if (_timeAgo >= period * 4) {
        return nfts.length * 1200;
      } else {
        return 0;
      }
    }

    //console.log('profitCalculation() ', profitCalculation())

    return (
      <div
        className={`col-span-2 transition-all duration-500 m-2  hover:scale-105 hover:drop-shadow-xl drop-shadow-md ${
          isClaimed ? "opacity-50 grayscale hover:opacity-80" : ""
        } ${hiddenClaim && isClaimed ? "hidden" : ""}`}
      >
        <div className="z-50 relative top-14 ml-2 opacity-75 text-xl cursor-pointer font-bold  text-white drop-shadow-md rounded-md px-1 flex flex-row items-center w-fit">
          <BsFillEyeFill
            onClick={() => viewNFTSet(nfts)}
            className="mt-1 opacity-50 hover:scale-110 hover:opacity-100 transition-all"
          />
        </div>
        <div className="z-50 relative top-9 -right-full -ml-36 font-bold bg-black/40 text-lg text-white drop-shadow-md rounded-md px-2 flex flex-row items-center w-fit">
          {isClaimed ? (
            <span className="flex flex-row items-center w-fit text-gray-400">
              <FaCheck className="mr-1" />
              All Claimed
            </span>
          ) : completeTime - currentTime * 1000 > 0 ? (
            <span className="flex flex-row items-center w-fit ">
              <GiAlarmClock className=" mr-1" />
              <Countdown date={completeTime} />
            </span>
          ) : (
            <span className="flex flex-row items-center w-fit text-green-400">
              <FaCheck className="mr-1" />
              Completed
            </span>
          )}
        </div>
        <div className="item-bg flex h-[350px] flex-col justify-center overflow-hidden items-center rounded-xl transition-all duration-200 ">
          <div className="border-0 h-[300px] overflow-hidden">
            <div
              className={`grid grid-flow-row gap-0 justify-center  px-5 border-0 ${
                nfts.length == 1
                  ? "grid-cols-1 scale-50 -mt-20"
                  : nfts.length == 3
                  ? "grid-cols-3 mt-12"
                  : nfts.length == 5
                  ? "grid-cols-5 mt-16"
                  : "grid-cols-5 mt-3"
              }`}
            >
              {nfts.map((x, i) => {
                return <MiniNFTCard key={i + 100} id={x} />;
              })}
            </div>
          </div>
          <div className="relative translate-y-36 -mt-36 w-full bg-white  text-gray-500 p-4 justify-center text-center transition-all duration-500 hover:-translate-y-0 z-50">
            <TimeLineStake
              period={period}
              startTime={startTime}
              isClaimed={isClaimed}
              claimTime={claimTime}
              currentTimeStamp={currentTime}
            />
            <span className="w-full flex flex-row justify-between text-yellow-500 font-bold -mt-2">
              <span>Crypterium Fantasy</span>
              <span>#{i + 1}</span>
            </span>
            <div className="w-full flex flex-row justify-between mb-0">
              <span>Value</span>
              <span className="flex flex-row text-black font-bold">
                <img
                  className="scale-75 items-center"
                  src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                />
                {separator(nfts.length * 150)}
              </span>
            </div>

            <div className="w-full flex flex-row justify-between mb-4">
              <span>Profitability (x{nfts.length})</span>
              <span className="flex flex-row text-black font-bold">
                <img
                  className="scale-75 items-center"
                  src={process.env.PUBLIC_URL + "/img/coins/ccoin.png"}
                />
                {separator(profitCalculation())}
              </span>
            </div>
            <div className="w-full flex flex-row justify-between ">
              <span>Items :</span>
              <span className="flex flex-row text-black ">{nfts.length}</span>
            </div>
            <div className="w-full flex flex-row justify-between">
              <span>Status :</span>
              <span className="flex flex-row text-black">
                {isClaimed ? "Claimed" : "In Stake"}
              </span>
            </div>
            <div className="w-full flex flex-row justify-between">
              <span>Since :</span>
              <span className="flex flex-row text-black">
                {isClaimed
                  ? js_ago(claimTime, { format: "medium" })
                  : js_ago(startTime, { format: "medium" })}
              </span>
            </div>
            {isClaimed ? (
              <div className="bg-gray-400 text-white w-full mt-2 font-bold border-[0px] p-2 rounded-md transition-all">
                Claimed
              </div>
            ) : (
              <div
                onClick={(e) => {
                  localStorage.setItem("i", i);
                  localStorage.setItem("pf", profitCalculation());

                  setInfo("Claim Stake #" + Number(i + 1));
                  if (profitCalculation() == 0) {
                    setError(
                      "Claimming a stake < 30 days will return noting! \n Are you sure?"
                    );
                  }
                  setState(1);
                  setOpenClaimStake(1);
                }}
                className="text-white w-full mt-2 font-bold border-[0px] p-2 bg-[#36300f] rounded-md cursor-pointer hover:bg-[#ffae00] transition-all"
              >
                {completeTime - currentTime * 1000 > 0 ? "Unstake" : "Claim"}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  //////////////////////////////////////// END STAKE COMPONENT ////////////////////////////////////////

  //////////////////////////////////////// MAIN COMPONENT START HERE ////////////////////////////////////////
  return (
    <div className="flex flex-col w-full justifyitem-center items-center mt-20 select-none">
      <AddNetwork CCOIN={CCOIN} CCSX={CCSX} BUSD={BUSD} />
      {/*<RewardCount />*/}

      <div className="mt-5 w-full md:w-5/6 p-5 flex flex-col justifyitem-center items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl transition-all">
        <div className="hidden md:block blur-sm absolute -top-4 md:right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 ">
          M y S t a k e
        </div>
        <div className="hidden md:block absolute -top-5 md:right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white ">
          M y S t a k e
        </div>
        <div className="md:hidden -mt-14 text-3xl font-bold text-yellow-700 blur-sm">
          M y S t a k e
        </div>
        <div className="md:hidden -mt-10 text-3xl font-bold text-white z-40">
          M y S t a k e
        </div>
        <div className="sticky top-20 w-full z-50 -mt-5 text-black bg-slate-600">
          {/* LINK TO BUY NFT */}
          <Link to="/treasurehunt">
            <div className="absolute top-5 left-5  flex flex-row text-2xl font-bold items-center bg-neutral-100 bg-opacity-70 backdrop-blur-xl hover:bg-opacity-90 border-2 border-black/70 hover:scale-105  transition-all rounded-xl p-3 drop-shadow-2xl cursor-pointer break-all ">
              <AiOutlineCaretLeft />
              Buy NFT
            </div>
          </Link>

          {/* RELOAD */}
          <div
            onClick={() => setRefresh(!refresh)}
            className="bg-neutral-100 bg-opacity-70 backdrop-blur-xl absolute top-5 right-0  flex flex-row text-2xl font-bold items-center  hover:bg-opacity-90 border-2 border-black/70 hover:scale-105  transition-all rounded-xl p-2 drop-shadow-2xl cursor-pointer break-all "
          >
            <FiRefreshCw className="hover:animate-spin" />
          </div>

          {/* HIDDEN CLAIMEM */}
          <div
            onClick={() => setHiddenClaim(!hiddenClaim)}
            className="bg-neutral-100 bg-opacity-70 backdrop-blur-xl absolute top-5 right-12  flex flex-row text-2xl font-bold items-center  hover:bg-opacity-90 border-2 border-black/70 hover:scale-105  transition-all rounded-xl p-2 drop-shadow-2xl cursor-pointer break-all "
          >
            {hiddenClaim ? (
              <GrFormViewHide className="scale-125" />
            ) : (
              <GrFormView className="scale-125" />
            )}
          </div>
        </div>
        {/* TEST 
                <div
                    onClick={() => setHiddenClaim(!hiddenClaim)}
                    className=" absolute top-5 right-24  flex flex-row text-2xl font-bold items-center bg-black/0  border-black/70 hover:scale-105  transition-all rounded-xl p-3 drop-shadow-2xl cursor-pointer break-all "
                >
                    {hiddenClaim ? (
                        <GrFormViewHide className="scale-125" />
                    ) : (
                        <GrFormView className="scale-125" />
                    )}
                </div>

                <div className="text-sm mt-10 md:mt-5">
                    NFT Smart Contract Address
                </div>

                <div
                    onClick={() =>
                        window.open(
                            'https://bscscan.com/address/' + erc1155Stake,
                            'bscscan'
                        )
                    }
                    className="text-sm md:text-2xl font-bold cursor-pointer"
                >
                    {erc1155Stake}
                </div>
            */}
        <div className="border-0 w-full grid grid-flow-row grid-cols-2 md:grid-cols-4 gap-2 p-5 mt-10">
          {!!account && stakeArr.length > 0 && Number.isInteger(period) ? (
            stakeArr.map((result, i) => (
              <Stake
                i={i}
                key={i}
                nfts={result.nftId}
                startTime={result.startTime}
                claimTime={result.claimTime}
                isClaimed={result.isClaimed}
                period={period}
              />
            ))
          ) : !!account ? (
            <div
              id="loadingBox"
              className="mt-20 my-10 flex flex-row items-center text-2xl animate-pulse w-full col-span-5 justify-center"
            >
              <AiOutlineLoading3Quarters className="animate-spin mx-2" />
              Loading ...
              {setTimeout(() => {
                try {
                  document.getElementById("loadingBox").textContent =
                    "Stake no found";
                } catch (e) {
                  //console.error(e)
                }
              }, 9000)}
            </div>
          ) : (
            <span className="mt-20 my-10 flex flex-row items-center text-2xl animate-pulse w-full col-span-5 justify-center">
              <RiWallet3Line className="scale-150 mr-5" />
              Please Connect Wallet
            </span>
          )}
        </div>
      </div>

      {/*<Footer /> */}
      {/*///////////////////// //////////////////////////// */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openClaimStake && state != 0}
        className="flex flex-col"
      >
        <div className="bg-gray-100 flex flex-col justify-center items-center m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-3/5 w-full display-linebreak">
          <div
            onClick={() => setState(0)}
            className={`absolute bg-black/30 w-full backdrop-blur-md backdrop-grayscale-50 z-50 items-center py-40 text-white text-3xl ${
              !!account ? "hidden" : null
            }`}
          >
            Please Connect Wallet
            <br />
            <br />
            <span className="text-sm cursor-pointer p-1 border-2 rounded-md hover:bg-black/30">
              Close
            </span>
          </div>

          <div className="w-full flex flex-row justify-end">
            <FaWindowClose
              color="#cc0e00"
              className={`transition-all duration-100 scale-150 drop-shadow-lg ${
                state == 1 || state == 3 || state == 5
                  ? "cursor-pointer hover:scale-125 "
                  : "grayscale opacity-10"
              }`}
              onClick={() => {
                setError("");
                state == 1 || state == 3 || state == 5
                  ? setState(0)
                  : setState(state);
              }}
            />
          </div>
          <div className="w-full flex flex-row justify-center">
            <div className="py-7 text-black text-3xl font-bold flex flex-col">
              {info}
              <span className="text-rose-500 font-bold">{error}</span>
            </div>
          </div>

          {state == 1 ? (
            <>
              <div
                id="coinSelect"
                className="w-full flex flex-row justify-center py-2"
              >
                <div className="md:w-1/3 w-2/3 rounded-lg mx-2 p-3 transition-all  bg-black/5 text-black drop-shadow-md scale-105">
                  <span className="text-xs flex flex-row justify-end">
                    Your Profit
                  </span>
                  <span className="flex flex-row justify-between">
                    <span className="flex flex-row">
                      <img
                        className="px-1"
                        src={process.env.PUBLIC_URL + "/img/coins/ccoin.png"}
                      />
                      CCOIN
                    </span>
                    <AnimatedNumber
                      value={localStorage.getItem("pf")}
                      formatValue={formatValue}
                      duration="500"
                    />
                  </span>
                </div>
              </div>
              <div
                onClick={() => {
                  setState(2);
                  setError("");
                  setInfo("Please Confirm on Metamask");
                  claimStake(localStorage.getItem("i"));
                }}
                className="w-2/3 bg-[#0059ff] text-gray-200 text-3xl font-bold p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
              >
                Confirm
              </div>
            </>
          ) : null}
          {state == 2 ? (
            <>
              <div className="relative w-2/3 bg-gray-400 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                <AiOutlineLoading3Quarters
                  color="inherit"
                  className="absolute left-1/4 mx-3 font-bold animate-spin"
                />
                <span className="animate-pulse text-3xl font-bold">
                  Waiting
                </span>
              </div>
            </>
          ) : null}

          {state == 3 ? (
            <>
              <div
                onClick={() => {
                  setState(0);
                  setInfo("");
                  setError("");
                  setOpenClaimStake(0);
                  setRefresh(!refresh);
                }}
                className="w-2/3 bg-[#0f63ff] text-white text-3xl font-bold p-2 m-2 my-4 hover:scale-105 transition-all rounded-md shadow-lg items-center justify-center content-center flex flex-row cursor-pointer"
              >
                Ok
              </div>
            </>
          ) : null}

          {state == 6 ? (
            <>
              <div
                onClick={() => {
                  setInfo("");
                  setError("");
                  setState(0);
                }}
                className="w-2/3 bg-[#0059ff] text-white text-3xl font-bold p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
              >
                Close
              </div>
            </>
          ) : null}

          <br />
        </div>
        <br />
      </Backdrop>
      {/*///////////////////// THE PRIZE //////////////////////////// */}
      <Backdrop
        //onClick={() => setOpenThePrize(false)}
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openThePrize && state != 0}
        className="flex flex-col"
      >
        <div className=" m-0 w-full h-screen overflow-hidden">
          <div className="w-full">
            <div
              onClick={() => setShowItems(true)}
              className={`relative overflow-hidden bg-black/70 align-middle content-center text-right backdrop-blur-2xl float-left w-1/2 h-screen z-50  cursor-pointer ${
                showItems
                  ? "-translate-x-full delay-500 transition-all duration-1000"
                  : ""
              }`}
            >
              <h1>Click to reveal&nbsp;</h1>
            </div>

            <div className="bg-[#100e17] absolute w-full h-full z-10 content-center justify-center items-center flex flex-col">
              <span className="text-gray-300 text-4xl font-bold my-9 -mt-9">
                Congratulations!, you've got...
              </span>
              <div
                className={`grid grid-flow-row gap-2 justify-center border-0 ${
                  nfts.length == 1
                    ? "grid-cols-1"
                    : nfts.length == 3
                    ? "grid-cols-3"
                    : "grid-cols-5"
                }`}
              >
                {nfts.map((x, i) => {
                  return <NFTCard key={i} id={x} />;
                })}
              </div>
              <div
                onClick={() => {
                  setShowItems(false);
                  setTimeout(5000, []);
                  setOpenThePrize(false);
                }}
                className="cursor-pointer text-xl font-bold p-3 bg-white/10 text-gray-300 rounded-md mt-5 transition-all duration-100 hover:scale-105 hover:bg-orange-400"
              >
                Done
              </div>
              <div
                onClick={() => {
                  html2canvas(document.body, {
                    useCORS: true,
                    allowTaint: true,
                  }).then((canvas) => {
                    let a = document.createElement("a");
                    a.download = nfts + ".png";
                    a.href = canvas.toDataURL("image/png");
                    a.click(); // MAY NOT ALWAYS WORK!
                  });
                }}
                className="hidden cursor-pointer text-xl font-bold p-3 bg-white/10 text-gray-300 rounded-md mt-5 transition-all duration-100 hover:scale-105 hover:bg-orange-400"
              >
                {" "}
                Screen Shot
              </div>
            </div>

            <div
              onClick={() => setShowItems(true)}
              className={`relative   bg-black/70 backdrop-blur-2xl float-left w-1/2 h-screen z-50  cursor-pointer ${
                showItems
                  ? "translate-x-full delay-500 transition-all duration-1000"
                  : ""
              }`}
            >
              <h1>a special reward</h1>
              <div
                className={`transition-all duration-300 border-2 w-fit
                                    ${showItems ? "opacity-0 " : ""}
                                `}
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/loadingRandom.gif"}
                  className="fixed top-1/4 -left-1/2  z-50 drop-shadow-lg transition-all"
                />
                {/*
                                <GiUnlocking
                                    
                                    className="fixed -mt-10 top-1/2 -left-12 z-50 hover:scale-105 drop-shadow-lg transition-all"
                        /> */}
              </div>
            </div>
          </div>
        </div>
      </Backdrop>
    </div>
  );
}

export default MyStake;
