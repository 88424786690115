import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import { FaChevronRight, FaCheck, FaWindowClose } from "react-icons/fa";
import { TiLockClosedOutline, TiLockOpenOutline } from "react-icons/ti";
import { BsFillEyeFill } from "react-icons/bs";
import { GiAlarmClock } from "react-icons/gi";
import CircularProgress from "@mui/material/CircularProgress";
import Countdown from "react-countdown";
import TimeLine from "./Timeline";
import AnimatedNumber from "animated-number-react";
import js_ago from "js-ago";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";

const formatValue = (value) => `${separator(Number(value).toFixed(0))}`;
function separator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const abi = require("../abi/eggHatch");
const isMetaMaskInstalled = () => {
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};

// http://ccoin.crypterium.game/nft/pet/j/5 << json uri
function EggDetailsV2({
  address,
  eggHatchAddress,
  i,
  eggId,
  tokens,
  startTime,
  stopTime,
  neverClaimed,
  currentTime,
  period,
  props,
}) {
  const [open, setOpen] = useState(false); //false
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState(0);
  const [claimState, setClaimState] = useState(0); //0
  const [account, setAccount] = useState();
  const [info, setInfo] = useState();
  const [error, setError] = useState();
  const [eggName, setEggName] = useState();
  const [hiddenClaim, setHiddenClaim] = useState(true);
  const [claimUp, setClaimUp] = useState();
  //const period = 900 // 1 hour
  //const period = 1296000 // 15 days
  const currentTimeStamp = Math.floor(Date.now() / 1000);
  const wc = useSelector((state) => state.walletConnect.value);
  //console.log('currentTimeStamp:', currentTimeStamp)
  const handleClose = () => {
    setOpen(false);
  };

  let provider;
  let ethereum = window.ethereum;
  let tempSigner;

  if (!!wc.account) {
    //console.log('WC!')
    ethereum = wc.provider;
    provider = wc.web3Provider;
    tempSigner = provider.getSigner();
    ethereum.on("accountsChanged", (accounts) => setState(state + 1));
  } else if (isMetaMaskInstalled()) {
    //console.log('MT!')
    ethereum = window.ethereum;
    provider = new ethers.providers.Web3Provider(ethereum);
    tempSigner = provider.getSigner();
    ethereum.on("accountsChanged", (accounts) => setState(state + 1));
  }

  useEffect(() => {
    if (!!wc.account || isMetaMaskInstalled()) {
      console.log("account:", account);
    }
    return () => {};
  }, [wc.account, account]);

  useEffect(() => {
    getNftName();
    setClaimUp(eggId);
    return () => {};
  }, []);

  const completeTime = (startTime + period * 4) * 1000;
  /*
    console.log('completeTime:', completeTime - currentTime)
    console.log('stopTime:', stopTime)
    console.log('neverClaimed', i, ':', neverClaimed)
    console.log('currentTime:', currentTime)
    console.log('startTime:', startTime)
    */
  function profitCalculation() {
    const _timeAgo = (neverClaimed ? stopTime : currentTime) - startTime;

    if (_timeAgo > period * 2 && _timeAgo < period * 3) {
      return eggId.length * 200;
    } else if (_timeAgo >= period * 3 && _timeAgo < period * 4) {
      return eggId.length * 600;
    } else if (_timeAgo >= period * 4) {
      return eggId.length * 1200;
    } else {
      return 0;
    }
  }

  async function getNftName() {
    let _nftName;
    await axios
      .get("https://nft-assets.crypterium.game/pets/j/" + eggId)
      .then((x) => {
        //console.log('x:', x)
        _nftName = x.data.name;
        setEggName(x.data.name);
        return x.data.name;
      })
      .then(() => {
        setEggName(_nftName);
        //console.log('_nftName:', _nftName)
      })
      .catch((err) => console.log("err:", err));
  }

  async function claimNFT(_index) {
    const contract = new ethers.Contract(eggHatchAddress, abi, tempSigner);
    const tx = await contract.claimNFT(_index).catch((err) => {
      console.log(err);
      handleClose();
      //setError(err)
    });
    setInfo("Waiting for Block confirmation");
    const receipt = await tx.wait(1).then((tx) => {
      setInfo("Transaction complete");
      //setState(state + 1)
      setClaimState(2);
      //handleClose()
    });
    setInfo("");
  }

  const day1 = (startTime + period) * 1000;
  const day2 = (startTime + period * 2) * 1000;
  const day3 = (startTime + period * 3) * 1000;
  const day4 = (startTime + period * 4) * 1000;

  let dateJust;
  let statusName;
  let nftUnlock = false;

  if (neverClaimed) {
    if ((currentTimeStamp - startTime) / period < 1) {
      dateJust = day1;
      statusName = "Breeding";
    } else if (
      (currentTimeStamp - startTime) / period >= 1 &&
      (currentTimeStamp - startTime) / period < 2
    ) {
      dateJust = day2;
      statusName = "Pre-Incubate";
    } else if (
      (currentTimeStamp - startTime) / period >= 2 &&
      (currentTimeStamp - startTime) / period < 4
    ) {
      dateJust = day4;
      statusName = "Incubate";
      nftUnlock = true;
    } else {
      statusName = "Hatched!";
      nftUnlock = true;
    }
  } else {
    if ((currentTimeStamp - startTime) / period < 1) {
      dateJust = day1;
      statusName = "Pre-Incubate";
    } else if (
      (currentTimeStamp - startTime) / period >= 1 &&
      (currentTimeStamp - startTime) / period < 3
    ) {
      dateJust = day3;
      statusName = "Incubate";
    } else if ((currentTimeStamp - startTime) / period > 3) {
      statusName = "Hatched!";
      nftUnlock = true;
    }
  }

  //const time = startTime * 1000 + 604800 * 1000
  const onClick = () => {
    setShowModal(!showModal);
  };
  let fee;
  tokens == 15000 ? (fee = 1000) : (fee = 500);

  return (
    <>
      <div
        className={`col-span-1 transition-all duration-500 m-2  hover:scale-105 hover:drop-shadow-xl drop-shadow-md z-40  ${
          stopTime > 0 ? "opacity-50 grayscale hover:opacity-80" : ""
        } ${hiddenClaim && stopTime > 0 ? "" : ""}`}
      >
        <div className="z-50 relative top-9 -right-full -ml-36 font-bold bg-black/20 text-lg text-white drop-shadow-md rounded-md px-2 flex flex-row items-center w-fit">
          {stopTime > 0 ? (
            <span className="flex flex-row items-center w-fit text-gray-400">
              <FaCheck className="mr-1" />
              All Claimed
            </span>
          ) : completeTime - currentTime * 1000 > 0 ? (
            <span className="flex flex-row items-center w-fit ">
              <GiAlarmClock className=" mr-1" />
              <Countdown date={dateJust} />
            </span>
          ) : (
            <span className="flex flex-row items-center w-fit text-green-300">
              <FaCheck className="mr-1" />
              Completed
            </span>
          )}
        </div>
        <div className="egg-bg  flex h-[250px] flex-col justify-center overflow-hidden items-center rounded-xl transition-all duration-200 ">
          <div className="fixed w-[100px] py-0 border-0 -mt-20">
            <img
              src={
                `
                                    https://nft-assets.crypterium.game/pets/k/?addr=` +
                address +
                `&id=` +
                i +
                `${tokens == 15000 || tokens == 5000 ? `` : `&nothatched=true`}`
              }
              className="transition-all duration-500 p-4"
            />
          </div>

          <div className="translate-y-36 -mt-18 md:mt-0 w-full bg-white text-md text-gray-500 p-4 justify-center text-center transition-all duration-500 hover:translate-y-10">
            <TimeLine
              period={period}
              startTime={startTime}
              neverClaimed={neverClaimed}
              tokens={tokens}
            />
            <span className="w-full flex flex-row justify-between text-blue-500 font-bold -mt-2">
              <span>NFT ID</span>
              <span>#{eggId}</span>
            </span>
            <div className="w-full flex flex-row justify-between mb-0">
              <span>Name</span>
              <span className="flex flex-row text-black ">
                {!!eggName ? eggName : "????"}
              </span>
            </div>

            <div className="w-full flex flex-row justify-between mb-3">
              <span>Stake Value</span>
              <span className="flex flex-row text-black ">
                <img
                  className="scale-75 items-center"
                  src={process.env.PUBLIC_URL + "/img/coins/ccoin.png"}
                />
                <AnimatedNumber
                  //className="line-through "
                  value={tokens}
                  formatValue={formatValue}
                  duration="600"
                />
              </span>
            </div>

            <div className="w-full flex flex-row justify-between">
              <span>Status :</span>
              <span className="flex flex-row text-black">
                {stopTime > 0 ? "Claimed" : statusName}
              </span>
            </div>
            <div className="w-full flex flex-row justify-between mb-2">
              <span>Since :</span>
              <span className="flex flex-row text-black">
                {stopTime > 0
                  ? js_ago(stopTime, { format: "medium" })
                  : js_ago(startTime, { format: "medium" })}
              </span>
            </div>
            {nftUnlock && !stopTime > 0 ? (
              <div
                onClick={() => {
                  setOpen(true);
                  setClaimState(0);
                  setInfo("Your claimable assets");
                }}
                className="w-full bg-[#0f63ff] m-1 mb-9 flex flex-row items-center justify-center alig rounded-lg text-gray-100 text-md font-bold shadow-md transition-all py-1 duration-200 hover:scale-105 cursor-pointer hover:bg-[#ffae00]"
              >
                <TiLockOpenOutline className="mr-1" />
                Claim NFT{" "}
              </div>
            ) : (
              <div className="w-full bg-[#e0e0de] m-1 mb-9 flex flex-row items-center justify-center alig rounded-lg text-gray-400 text-md font-bold shadow-md transition-all py-1 duration-200">
                <TiLockClosedOutline className="mr-1" />
                Claim NFT
              </div>
            )}
          </div>
        </div>

        {/* ////////////////////////////////////// B L A C K  D R O P /////////////////////////////////////////// */}

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 20,
          }}
          open={open}
          className="flex flex-col"
        >
          <div className="flex flex-col justify-center items-center  bg-white m-4 text-center text-gray-900 text-md p-1 rounded-lg w-full display-linebreak bg-[url('https://ccoin.crypterium.game/img/bgpix6.jpg')]">
            <div className="w-full flex flex-row justify-between">
              <div />
              <div className="py-1">{info}</div>
              <FaWindowClose
                color="red"
                className={` transition-all duration-100 drop-shadow-lg ${
                  claimState == 1
                    ? "grayscale opacity-10"
                    : "cursor-pointer hover:scale-125 "
                }`}
                onClick={() => {
                  if (claimState != 1) {
                    if (claimState == 2) {
                      window.location.reload();
                    }
                    setOpen(false);
                    setClaimState(0);
                    setState(state + 1);
                  }
                }}
              />
            </div>

            {claimState == 0 ? (
              <div className="w-full border-0 flex flex-row justify-between">
                <div className="w-full border-0 grid grid-flow-row grid-cols-5 justify-center items-center bg-slate-400/10 drop-shadow-xl rounded-lg p-3 py-4 m-1 my-1 transition-all duration-200 hover:scale-105">
                  <span className="col-span-5 text-bold text-blue-500 text-md">
                    NFT ID : {eggId}
                  </span>
                  <span className="col-span-2">Stake Value</span>
                  <AnimatedNumber
                    //className="line-through "
                    value={tokens}
                    formatValue={formatValue}
                    duration="600"
                    className="col-span-2"
                  />
                  <img
                    className="col-span-1 scale-50 "
                    src={process.env.PUBLIC_URL + "/img/ccoin_32.png"}
                  />

                  <span className="col-span-2 text-rose-500">fee</span>
                  <AnimatedNumber
                    //className="line-through "
                    value={-fee}
                    formatValue={formatValue}
                    duration="600"
                    className="col-span-2 text-rose-500"
                  />
                  <img
                    className="col-span-1 scale-50"
                    src={process.env.PUBLIC_URL + "/img/ccoin_32.png"}
                  />
                  <hr className="w-full col-span-5 my-1" />

                  <span className="col-span-2 font-bold">Total</span>
                  <AnimatedNumber
                    //className="line-through "
                    value={tokens - fee}
                    formatValue={formatValue}
                    duration="600"
                    className="col-span-2 font-bold"
                  />
                  <img
                    className="col-span-1 scale-50"
                    src={process.env.PUBLIC_URL + "/img/ccoin_32.png"}
                  />
                </div>
              </div>
            ) : claimState == 2 ? null : (
              <CircularProgress color="inherit" className="mb-5 my-5" />
            )}

            {claimState == 2 ? (
              <div className="w-full flex flex-col justify-center items-center ">
                <span className="text-blue-800 text-md font-bold">
                  Congratuation! <br />
                </span>
                <span className="text-xs -mt-1">You've got</span>
                <span className="flex flex-row justify-center items-center">
                  <div className="w-full  border-0 grid grid-flow-row grid-cols-3 justify-center items-center bg-slate-400/10 drop-shadow-xl  rounded-lg p-1 m-0 transition-all duration-200 hover:scale-110">
                    <span className="col-span-5 text-bold text-blue-600 text-sm font-bold">
                      NFT ID : {eggId}
                    </span>
                    <div className="w-full col-span-5 border-0 flex flex-col justify-center items-center">
                      <img
                        className="drop-shadow-lg hover:scale-200 transition-all duration-500 hover:-mt-16 z-50"
                        width={150}
                        src={`http://nft-assets.crypterium.game/pets/i/?i=${eggId}${
                          (tokens == 15000 || tokens == 5000) &&
                          statusName == "Hatched!"
                            ? "&sample=true"
                            : ""
                        }`}
                      />
                    </div>
                    +
                    <AnimatedNumber
                      //className="line-through "
                      value={tokens - 1000}
                      formatValue={formatValue}
                      duration="600"
                      className="col-span-2 font-bold text-md"
                    />
                    <img
                      className="col-span-1 scale-50"
                      src={process.env.PUBLIC_URL + "/img/ccoin_32.png"}
                    />
                  </div>
                </span>
                <div
                  onClick={() => {
                    if (claimState != 1) {
                      if (claimState == 2) {
                        window.location.reload();
                      }
                      setOpen(false);
                      setClaimState(0);
                      setState(state + 1);
                    }
                  }}
                  className="w-2/3 bg-[#0f63ff] text-white p-2 hover:scale-105 transition-all rounded-md shadow-lg items-center justify-center content-center flex flex-row cursor-pointer"
                >
                  Complete!
                </div>
              </div>
            ) : null}

            <br />
            <div
              onClick={() => {
                claimNFT(i);
                setClaimState(1);
                setInfo("Please Comfirm on Metamask");
              }}
              className={`w-1/2 bg-[#0f63ff] m-2 p-2 flex flex-row items-center justify-center alig rounded-md text-gray-100 shadow-md transition-all duration-200 hover:scale-105 cursor-pointer hover:bg-[#ffae00] ${
                claimState ? "hidden" : null
              } `}
            >
              Claim
            </div>
          </div>
          <br />
        </Backdrop>
      </div>
      {/* /////////////////////////////////////////////////////////////////////////////// */}
    </>
  );
}

export default EggDetailsV2;
