import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import AnimatedNumber from 'animated-number-react'
import {
    useCSVReader,
    lightenDarkenColor,
    formatFileSize,
} from 'react-papaparse'

//const smcPoolAddress = '0x459a971A6d1dd79d3C7E97d6e3834448986779fb' //Rinkeby
const smcPoolAddress = '0xC5722502Be6803883a94D5FECbbf35A132800AF0'
const abi = require('../abi/smcPool')
const formatValue = (value) => `${separator(Number(value).toFixed(1))}`
let ierc20 = require('../abi/IERC20')
const coin = '0x77f2c17bda8489e316cc26b34dd7ae079dc455ce'

function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const isMetaMaskInstalled = () => {
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}

function dynamicSort(property) {
    var sortOrder = 1
    if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
        return result * sortOrder
    }
}

function AddrList({ result }) {
    return (
        <div className="w-full grid grid-cols-3 gap-3 grid-flow-row  text-gray-700 text-xm border-b py-1">
            <div className="col-span-1 text-center w-100">
                {result[0].slice(0, 5) + '....' + result[0].slice(-5)}
            </div>
            <div className="col-span-1 px-3 text-center">
                {separator(result[1])}
            </div>
            <div className="col-span-1 px-3 text-right">
                {new Date(result[2] * 1000).toLocaleString('en-US', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hourCycle: 'h24',
                })}
            </div>
        </div>
    )
}

function AdminConsole() {
    const { CSVReader } = useCSVReader()
    const [csvResult, setCsvResult] = useState()
    const [open, setOpen] = useState(false)
    const [isProcess, setIsProcess] = useState(false)
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [ledgers, setLedgers] = useState([])
    const [contractBalance, setContractBalance] = useState(0)
    const [adminAddress, setAdminAddress] = useState('')
    const [operatorAddress, setOperatorAddress] = useState('')
    const wc = useSelector((state) => state.walletConnect.value)

    const handleClose = () => {
        setOpen(false)
    }
    const handleToggle = () => {
        setOpen(!open)
    }
    let addrsArr = []
    let amountsArr = []
    let timeStampsArr = []

    let provider
    let ethereum = window.ethereum
    let tempSigner

    if (!!wc.account) {
        console.log('WC!')
        ethereum = wc.provider
        provider = wc.web3Provider
        tempSigner = provider.getSigner()
    } else if (isMetaMaskInstalled()) {
        console.log('MT!')
        ethereum = window.ethereum
        provider = new ethers.providers.Web3Provider(ethereum)
        tempSigner = provider.getSigner()
    }

    useEffect(() => {
        console.log(csvResult)

        return () => {}
    }, [csvResult])

    useEffect(() => {
        if (!!wc.account || isMetaMaskInstalled()) {
            getContractBalance()
            getAdminAddress()
            getOperatorAddress()
        }

        return () => {}
    }, [])

    if (csvResult != undefined) {
        csvResult.map((result) => {
            addrsArr.push(result[0])
            amountsArr.push(result[1])
            timeStampsArr.push(result[2])
        })
    }

    console.log('addrsArr:', addrsArr)
    console.log('amountsArr:', amountsArr)
    console.log('timeStampsArr:', timeStampsArr)

    async function getContractBalance() {
        const smcTokenContract = new ethers.Contract(coin, ierc20, tempSigner)
        await smcTokenContract.balanceOf(smcPoolAddress).then((x) => {
            setContractBalance((x / 10 ** 18).toFixed(2))
            console.log('balance', (x / 10 ** 18).toFixed(2))
        })
    }

    async function getAdminAddress() {
        const smcPool = new ethers.Contract(smcPoolAddress, abi, tempSigner)
        const getAdminAddress = await smcPool.callStatic.adminAddress()
        setAdminAddress(getAdminAddress.toString())
    }
    async function getOperatorAddress() {
        const smcPool = new ethers.Contract(smcPoolAddress, abi, tempSigner)
        const getOperatorAddress = await smcPool.callStatic.operatorAddress()
        setOperatorAddress(getOperatorAddress.toString())
    }
    async function addMultiLedger() {
        const smcPool = new ethers.Contract(smcPoolAddress, abi, tempSigner)
        const addLedger = await smcPool
            .AddMultiLedgers(addrsArr, amountsArr, timeStampsArr)
            .then((tx) => {
                console.log('tx:', tx)
                setInfo('Please wait for confirmation')
                setCsvResult()
                handleClose()
            })
            .catch((err) => {
                handleClose()
                setError(err.data.message)
            })
    }

    async function getBlockTimeStamp() {
        const smcPool = new ethers.Contract(smcPoolAddress, abi, tempSigner)
        const blockTimeStamp = await smcPool.callStatic.getBlockTimeStamp()
        console.log('blockTimeStamp', blockTimeStamp.toString())
    }

    async function signMgs() {
        const message = 'The Smallest Parts Are Every Where!'
        await tempSigner.signMessage(message).then((signature) => {
            console.log('Signature:', signature)
            // Recover Address from signature
            const result = ethers.utils.verifyMessage(message, signature)
            console.log('Recovered Address:', result)
        })
    }

    async function checkClaimAbleTokens() {
        const smcPool = new ethers.Contract(smcPoolAddress, abi, tempSigner)
        const address = await tempSigner.getAddress()
        const numOfLedger = await smcPool.callStatic.ledgerLength(address)
        console.log('tempSigner.getAddress()', await tempSigner.getAddress())

        console.log('numOfLedger', numOfLedger.toString())
        const checkClaimAbleTokens =
            await smcPool.callStatic.checkClaimAbleTokens()
        console.log(
            'checkClaimAbleTokens',
            checkClaimAbleTokens.toString() / 10 ** 18
        )
        //let ledgers = []
        for (let i = 0; i < numOfLedger.toString(); i++) {
            setLedgers(
                ledgers.push(await smcPool.callStatic.ledgers(address, i))
            )
        }
        setLedgers(ledgers.sort(dynamicSort('unlockTime')))
        console.log('ledgers:', ledgers)
    }

    return (
        <div className="flex flex-col w-full justifyitem-center items-center mt-20">
            <div className="md:w-3/5 w-full p-5 flex flex-col justifyitem-center items-center drop-shadow-lg rounded-3xl text-black bg-gray-100">
                <span className="text-blue-400 font-bold text-sm my-1">
                    {info}
                </span>
                <div className="text-sm md:text-xl self-center -mb-3 font-bold text-gray-700 break-all">
                    {smcPoolAddress}
                </div>
                <div className="w-full mt-5 grid grid-cols-4 gap-1 grid-flow-row text-sm text-center text-gray-500 font-bold content-center mb-5">
                    <div className="col-span-3 justify-self-center text-2xl text-blue-500 flex flex-col border rounded-md w-full p-2 border-gray-300">
                        <span className="text-xs font-medium -mt-1">
                            Contract Balance
                        </span>
                        <AnimatedNumber
                            value={contractBalance}
                            formatValue={formatValue}
                            duration="600"
                        />
                        <span className="text-lg font-medium">SMC</span>
                    </div>
                    <div className="col-span-1 justify-self-center text-2xl text-green-500 flex flex-col border rounded-md w-full p-2 border-gray-300">
                        <span className="text-xs font-medium -mt-1">
                            Status
                        </span>
                        Running
                        <button className="text-white text-sm w-full mt-1 border-[#3d4f7c] bg-[#6778a5] rounded-full cursor-pointer hover:bg-[#ff0000]">
                            PAUSE
                        </button>
                    </div>
                    <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-gray-300">
                        <span className="text-xs font-medium -mt-1">
                            {adminAddress.slice(0, 5) +
                                '....' +
                                adminAddress.slice(-4)}
                        </span>
                        Change Admin Address
                    </div>
                    <div className="col-span-2 justify-self-center text-mg text-grey-500 flex flex-col border rounded-md w-full p-2 border-gray-300">
                        <span className="text-xs font-medium -mt-1">
                            {operatorAddress.slice(0, 5) +
                                '....' +
                                operatorAddress.slice(-4)}
                        </span>
                        Change Operator Address
                    </div>
                </div>

                <CSVReader
                    onUploadAccepted={(results: any) => {
                        setCsvResult(results.data)
                    }}
                >
                    {({
                        getRootProps,
                        acceptedFile,
                        ProgressBar,
                        getRemoveFileProps,
                    }: any) => (
                        <>
                            <div className="w-full flex flex-row justify-between items-center">
                                <button
                                    type="button"
                                    {...getRootProps()}
                                    className="flex flex-row bg-slate-100 rounded-lg p-5 text-xs border-dashed border-2 border-slate-300 text-slate-400"
                                >
                                    <AiOutlineCloudUpload fontSize={18} />{' '}
                                    Browse or Drag & Drop .csv here
                                </button>
                                <div className="text-sm">
                                    {acceptedFile && acceptedFile.name}
                                </div>
                                <button
                                    {...getRemoveFileProps()}
                                    className="border bg-rose-300 rounded-lg p-1 text-xs"
                                    onClick={() => setCsvResult()}
                                >
                                    Clear
                                </button>
                            </div>
                            <ProgressBar />
                        </>
                    )}
                </CSVReader>

                <div className="w-full mt-5 grid grid-cols-3 gap-3 grid-flow-row border-b text-sm text-center text-gray-500 font-bold">
                    <div className="col-span-1">Address</div>{' '}
                    <div className="col-span-1">SMC</div>{' '}
                    <div className="col-span-1">Unlock Date</div>
                </div>
                <div className="w-full flex flex-col rounded-md justify-between text-sm ">
                    {csvResult != undefined ? (
                        csvResult.map((x, i) => <AddrList key={i} result={x} />)
                    ) : (
                        <div />
                    )}
                </div>

                <button
                    type="button"
                    onClick={() => {
                        setInfo('Waiting for your confirmation on Metamask')
                        handleToggle()
                        setIsProcess(true)
                        setError('')
                        addMultiLedger()
                    }}
                    className="text-white w-full mt-2 border-[1px] p-2 border-[#3d4f7c] bg-[#6778a5] rounded-full cursor-pointer hover:bg-[#87a0b8]"
                >
                    Inject
                </button>

                <span className="text-red-600 font-bold text-sm my-1">
                    {error}
                </span>
            </div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                className="flex flex-col"
            >
                <div className="bg-white m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-2/5 w-full display-linebreak">
                    <CircularProgress color="inherit" />
                    <br />
                    {info}
                </div>
                <br />
            </Backdrop>
        </div>
    )
}

export default AdminConsole
