import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FiRefreshCw } from 'react-icons/fi'
import axios, { Axios } from 'axios'
import js_ago from 'js-ago'

const formatValue = (value) => `${separator(Number(value).toFixed(0))}`
function separator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
const isMetaMaskInstalled = () => {
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
}
const abiStake = require('../abi/abiStake')

function Order({ i, transaction_hash, date, items }) {
    const _cardName = [
        'Angel Boot',
        'Angel Guard',
        'Angel Amulet',
        'Angel Halo',
        'Athena Shield',
        'Athena Boot',
        'Ares Ring',
        'Ares Amulet',
        'Apollo Armor',
        'Apollo Helmet',
    ]

    function uniq(a) {
        const arrId = a.sort().filter(function (item, pos, ary) {
            return !pos || item != ary[pos - 1]
        })
        let arrAmt = []
        a.sort().filter(function (item, pos, ary) {
            !pos || item != ary[pos - 1]
                ? arrAmt.push(1)
                : arrAmt[arrAmt.length - 1]++
        })
        let arrIdInt = arrId.map((i) => Number(i))
        return {
            arrId: arrIdInt,
            arrAmt: arrAmt,
        }
    }

    //console.log('arrId:', uniq(items).arrId)
    //console.log('arrAmt:', uniq(items).arrAmt)

    function NFT({ _id, _num }) {
        const _name = _cardName[_id - 1]
        const _textColor =
            _id < 5
                ? ' text-yellow-500 font-bold'
                : _id < 7
                ? ' text-gray-500 font-bold'
                : ' text-gray-400'

        const _flameColor =
            _id < 5
                ? ' item-nft-bg  font-bold'
                : _id < 7
                ? ' item-s-bg font-bold'
                : ' bg-gray-300'
        return (
            <div className={`mx-1 w-full justify-evenly  ${_textColor}`}>
                <div
                    className={`flex rounded-t-md shadow-md justify-center p-1 ${_flameColor}`}
                >
                    <img
                        className="transition-all duration-1000 dro"
                        src={
                            process.env.PUBLIC_URL + '/img/pix/' + _id + '.png'
                        }
                    />
                </div>
                <div className="bg-white shadow-md text-xs p-1 rounded-b-md">
                    {_name}
                    <br />x {_num}
                </div>
            </div>
        )
    }

    return (
        <div className="grid grid-flow-row grid-cols-6 gap-1 w-full justify-center text-center p-2 border-0 rounded-lg bg-white/40 text-black">
            <span className="col-span-6 md:col-span-1 row-span-1 text-sm  items-center">
                Transaction
            </span>

            <span className="col-span-6 md:col-span-5 row-span-1 overflow-hidden text-md text-slate-600 hover:text-orange-400 items-center my-1">
                <a
                    href={`https://bscscan.com/tx/${transaction_hash}`}
                    target="_blank"
                >
                    {transaction_hash}
                </a>
            </span>

            <span className="hidden md:block md:col-span-1 row-span-1  text-xl text-gray-500 font-bold items-center content-center">
                x {items.length}
            </span>
            <span className="col-span-6 md:col-span-5 row-span-2 text-xs text-yellow-500 flex flex-row justify-center">
                {uniq(items).arrId.map((x, i) => {
                    return (
                        <NFT
                            key={x + 100}
                            _id={x}
                            _num={uniq(items).arrAmt[i]}
                        />
                    )
                })}
            </span>
            <span className="col-span-6 md:col-span-1 row-span-1 text-sm flex flex-row justify-center content-center items-center">
                {js_ago(date, { format: 'medium' })}
            </span>
        </div>
    )
}

function OrderHistory({}) {
    const [refresh, setRefresh] = useState(false)
    const [orders, setOrders] = useState([])
    const wc = useSelector((state) => state.walletConnect.value)

    useEffect(() => {
        getOrderHistrory()

        return () => {
            //setOrders([])
        }
    }, [])

    async function getOrderHistrory() {
        let _orders = []
        axios
            .get(
                'https://snybclbtdhxo.grandmoralis.com:2053/server/functions/fetchNFTOrders?limit=10&offset=0'
            )
            .then((x) => {
                const result = x.data.result
                result.forEach((element) => {
                    _orders.push(element)
                })
            })
            .then(() => {
                setOrders(_orders)
            })
    }

    return (
        <>
            <div className="mt-10 w-full md:w-5/6 p-5 py-9 flex flex-col justify-evenly items-center drop-shadow-lg rounded-xl text-black bg-neutral-100 bg-opacity-70 backdrop-blur-xl transition-all">
                <div className="hidden md:block blur-sm absolute -top-4 md:right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 ">
                    R e c e n t l y D r a w
                </div>
                <div className="hidden md:block absolute -top-5 md:right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white ">
                    R e c e n t l y D r a w
                </div>
                <div className="md:hidden -mt-14 text-3xl font-bold text-yellow-700 blur-sm">
                    R e c e n t l y D r a w
                </div>
                <div className="md:hidden -mt-10 text-3xl font-bold text-white z-40">
                    R e c e n t l y D r a w
                </div>
                {/* RELOAD */}
                <div
                    onClick={() => setRefresh(!refresh)}
                    className=" absolute top-5 right-10 opacity-5 flex flex-row text-2xl font-bold items-center bg-black/0  border-black/50 hover:scale-105  transition-all rounded-xl p-3 drop-shadow-2xl cursor-pointer break-all "
                >
                    <FiRefreshCw className="hover:animate-spin" />
                </div>

                <div className="grid grid-flow-row gap-2 mt-10">
                    {orders.length > 0 ? (
                        orders.map((result, i) => {
                            const date = new Date(result.block_timestamp.iso)
                            const timeStamp = date.getTime() / 1000
                            return (
                                <Order
                                    key={i}
                                    transaction_hash={result.transaction_hash}
                                    items={result.items}
                                    date={timeStamp}
                                />
                            )
                        })
                    ) : (
                        <div
                            onClick={() => setRefresh(!refresh)}
                            className=" opacity-30 flex flex-row text-2xl font-bold items-center bg-black/0  border-black/50 hover:scale-105  transition-all rounded-xl p-3 drop-shadow-2xl cursor-pointer break-all "
                        >
                            <FiRefreshCw className="hover:animate-spin mr-2" />{' '}
                            View Last 10 orders
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default OrderHistory
