import {
  Navbar,
  MyTokens,
  AdminConsole,
  Incubator,
  // AddNetwork,
  NFTSale,
  MyStake,
} from "./components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App h-max p-5 relative">
        <Navbar />

        <Routes>
          <Route path="/egg" element={<Incubator />} />
          <Route path="/" element={<NFTSale />} />
          <Route path="/admin" element={<AdminConsole />} />
          <Route path="/stake" element={<MyTokens />} />
          <Route path="/NFTSale" element={<NFTSale />} />
          <Route path="/MyStake" element={<MyStake />} />
          <Route path="/treasurehunt" element={<NFTSale />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
