import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import AnimatedNumber from "animated-number-react";
import Backdrop from "@mui/material/Backdrop";
import {
  AiOutlineLoading3Quarters,
  AiFillStar,
  AiOutlineStar,
} from "react-icons/ai";
import { MdOutlineViewInAr } from "react-icons/md";
import { BiPurchaseTag } from "react-icons/bi";
import { FaWindowClose, FaBell } from "react-icons/fa";
import html2canvas from "html2canvas";
import AddNetwork from "./AddNetwork";
import axios, { Axios } from "axios";
import RewardCountAPI from "./RewardCountAPI";
import OrderHistory from "./OrderHistory";

const mystryBoxContract = process.env.REACT_APP_MYSTRY_BOX_CONTRACT;
const CCOIN = process.env.REACT_APP_CCOIN;
const CCSX = process.env.REACT_APP_CCSX;
const BUSD = process.env.REACT_APP_BUSD;
/*
const mystryBoxContract = '0xb27c21cC5f31cA6748926a9CF6b1c6c7E7C370A6' //BSC Testnet
const CCOIN = '0xbFB0be698655eCdEb6dDe80831dfb3C6553c4C6D' //BSC Testnet
const CCSX = '0x0D82Bdd0Db436dBc6473B58f0B55d9854cAE3F87' //BSC Testnet
const BUSD = '0x32ed57673EC8a0c6e5c4cd0c53e2d0a5be1497f9' //busd testnet
*/
/*
const mystryBoxContract = '0x4F2151d32d7a1BeDC04C633ec10C07498355b9Ea' //BSC Mainnet
const CCOIN = '0xc209831f7349D4E200d420326B3401899Ab9Ae68' //BSC Mainnet
const CCSX = '0x1819615B0643c54A6096af42a72C761f85E7ec37' //BSC Mainnet
const BUSD = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56' //busd Mainnet
*/

const boxPrice = 150;
const IERC20 = require("../abi/IERC20");
const rcAbi = require("../abi/rcAbi");
const formatValue = (value) => `${separator(Number(value).toFixed(0))}`;
function separator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const isMetaMaskInstalled = () => {
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};

function NFTCard({ id }) {
  const [nftName, setNftName] = useState();
  async function getMetaData() {
    await axios
      .get("https://nft-assets.crypterium.game/items/j/" + id)
      .then((x) => {
        //console.log('x:', x.data.name)
        setNftName(x.data.name);
      })
      .catch((err) => console.log("err:", err));
  }
  getMetaData();
  return (
    <div className="card col-span-1 flex flex-col scale-75 md:scale-100 -m-10 md:m-0">
      <img
        className="absolute scale-125 hover:scale-150 transition-all duration-1000 origin-top"
        src={`https://nft-assets.crypterium.game/items/i/` + id}
      />
      <div className="absolute -bottom-0 text-center  bg-black/60 p-2 text-gray-200 w-full transition-all duration-200 z-50">
        {nftName}
        <br />
      </div>
    </div>
  );
}

const array2 = [8, 9, 4, 4, 5, 4, 5, 9, 4, 9];
function uniq(a) {
  const arrId = a.sort().filter(function (item, pos, ary) {
    return !pos || item != ary[pos - 1];
  });
  let arrAmt = [];
  a.sort().filter(function (item, pos, ary) {
    !pos || item != ary[pos - 1] ? arrAmt.push(1) : arrAmt[arrAmt.length - 1]++;
  });
  return {
    arrId: arrId,
    arrAmt: arrAmt,
  };
}

function CardDetail({ id }) {
  return (
    <div className="cardFlip transition-all duration-300 col-span-1 hover:scale-110">
      <div className="contentFlip ">
        <div className="frontFlip ">
          <img
            className="absolute scale-125  transition-all duration-1000 origin-top"
            src={process.env.PUBLIC_URL + "/img/backcard.gif"}
          />
        </div>
        <div className="backFlip">
          <img
            className="absolute scale-125 transition-all duration-1000 origin-top drop-shadow-lg"
            src={`https://nft-assets.crypterium.game/items/i/${id}`}
          />
        </div>
      </div>
      <div className="showup relative w-full bg-black/10 transition-all duration-500 opacity-0 hover:opacity-100">
        XXXXXXXXXXXXXXXX
      </div>
    </div>
  );
}

function NFTSale() {
  const [error, setError] = useState();
  const [info, setInfo] = useState();
  const [account, setAccount] = useState();
  const [state, setState] = useState(0);
  const [openApprove, setOpenApprove] = useState(false);
  const [openThePrize, setOpenThePrize] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [BUSDBalance, setBUSDBalance] = useState(0);
  const [numBox, setNumBox] = useState(1);
  const [nfts, setNfts] = useState([]);
  const [ping, setPing] = useState(false);

  const wc = useSelector((state) => state.walletConnect.value);

  let provider;
  let ethereum = window.ethereum;
  let tempSigner;

  if (!!wc.account) {
    ethereum = wc.provider;
    provider = wc.web3Provider;
    tempSigner = provider.getSigner();
    ethereum.on("accountsChanged", (accounts) => setState(state));
  } else if (isMetaMaskInstalled()) {
    ethereum = window.ethereum;
    provider = new ethers.providers.Web3Provider(ethereum);
    tempSigner = provider.getSigner();
    ethereum.on("accountsChanged", (accounts) => setState(state));
  }

  useEffect(() => {
    if (!!wc.account || isMetaMaskInstalled()) {
      getCurrentAccount();
      getBUSDBalance();
      console.log("account:", account);
    }
    return () => {};
  }, [state, wc.account, account]);

  useEffect(() => {
    window.location.href.includes("ref")
      ? localStorage.setItem("ref", window.location.href.split("ref=")[1])
      : localStorage.setItem("ref", "cf000000000");

    return () => {};
  }, []);

  //// GET Require error from contract using catch promist ////
  async function reason(_hash) {
    let hash = _hash;
    let tx = await provider.getTransaction(hash);
    if (!tx) {
      console.log("Reason : tx not found");
    } else {
      let code = await provider.call(tx, tx.blockNumber);
      return ethers.utils.toUtf8String(code.substr(138));
    }
  }

  console.log("ref code:", localStorage.getItem("ref"));
  let referal = localStorage.getItem("ref");

  async function getCurrentAccount() {
    const accounts = await ethereum.request({
      method: "eth_accounts",
    });
    setAccount(accounts[0]);
    return accounts[0];
  }

  async function unBox(_num) {
    if (BUSDBalance < parseInt(_num * boxPrice)) {
      setInfo("");
      setError("Insufficient BUSD Balance.");
      setState(6);
      return;
    }
    let gasLimit;
    _num == 1
      ? (gasLimit = 330000)
      : _num <= 3
      ? (gasLimit = 350000)
      : _num <= 5
      ? (gasLimit = 630000)
      : (gasLimit = 730000);

    try {
      const contract = new ethers.Contract(
        mystryBoxContract,
        rcAbi,
        tempSigner
      );
      //const gasPrice = tempSigner.gasPrice();
      //const gasLimit = contract.estimateGas.randomBox(_num);
      const tx = await contract.randomBox(_num, referal, {
        gasLimit: gasLimit,
      }); //REMOVE GAS LIMITE ON PRODUCTION
      setInfo("Waiting for block Confirmation");
      const uB = await tx.wait(1).then((result) => {
        //console.log('Result:', result.events[2])
        const logs = result.events[2];
        const iface = new ethers.utils.Interface([
          "event OpenBox(address _address,uint256 _amount,uint256[] items,string _ref)",
        ]);
        const decodeOutput = iface.decodeEventLog(
          "OpenBox",
          logs.data,
          logs.topics
        );
        //console.log('Address:', decodeOutput[0])
        //console.log('Amount:', decodeOutput[1].toNumber())
        let nftArray = [];
        for (let i = 0; i < decodeOutput[2].length; i++) {
          nftArray.push(decodeOutput[2][i].toNumber());
          //console.log('Items:', decodeOutput[2][i].toNumber())
        }
        setNfts(nftArray);
        localStorage.setItem("lastPurchase", JSON.stringify(nftArray));
        console.log("nftArray:", nftArray);
        setInfo("Randomizing your NFTs.");
        setTimeout(() => {
          setInfo("Your NFTs are ready.");
          setState(5);
          //viewLastPurchase()
        }, 4000);
      });
    } catch (err) {
      console.log("err.transaction.hash:", err.transaction.hash);
      await reason(err.transaction.hash).catch((x) => {
        console.log("REASONY:", x.data);
        x.data.message == "out of gas"
          ? setError("Trasaction Error : Out of gas")
          : setError(x.data.message);
      });
      //let reason = ethers.utils.toUtf8String('0x' + code.substr(138))
      //console.log('revert reason:', reason)
      setInfo("");
      setState(6);
    }
  }

  async function getBUSDBalance() {
    try {
      const constract = new ethers.Contract(BUSD, IERC20, tempSigner);
      const balance = await constract.callStatic.balanceOf(
        tempSigner.getAddress()
      );
      setBUSDBalance(parseInt(ethers.utils.formatEther(balance)));
    } catch (err) {
      console.log("err: ", err);
    }
  }

  async function busdApproval(_amount) {
    try {
      const constract = new ethers.Contract(BUSD, IERC20, tempSigner);
      if (BUSDBalance >= parseInt(_amount)) {
        // BALANCE CHECK IF ENOUGHT
        const checkAllowance = await constract.callStatic.allowance(
          account,
          mystryBoxContract
        );
        if (
          // ALLOWANCE CHECK
          parseInt(_amount) > parseInt(ethers.utils.formatEther(checkAllowance))
        ) {
          // IF NOT ENOUGHT ALLOWANCE

          setInfo("First, We need your BUSD approval");
          setError("");
          setState(1);
        } else {
          // IF BOTH BALANCE AND ALLOWANCE IS ENOUGHT
          setState(2);
          setError("");
          setInfo("Approve BUSD");
          getBUSDApprove(numBox * boxPrice);
        }
      } else {
        // BALANCE NOT ENOUGHT
        setInfo("");
        setError("Insufficient BUSD Balance.");
        setState(6);
      }
    } catch (err) {
      console.log("err:", err);
    }
  }

  async function getBUSDApprove(_amount) {
    try {
      const constract = new ethers.Contract(BUSD, IERC20, tempSigner);

      if (BUSDBalance >= parseInt(_amount)) {
        const checkAllowance = await constract.callStatic.allowance(
          account,
          mystryBoxContract
        );

        if (
          parseInt(_amount) > parseInt(ethers.utils.formatEther(checkAllowance))
        ) {
          setInfo("Please Approve Your BUSD");
          console.log("BUSDBalance:", BUSDBalance);
          const tx = await constract.approve(
            mystryBoxContract,
            ethers.utils.parseEther(BUSDBalance.toString())
          );
          setInfo("Waiting for block confirmation");
          const receipt = await tx.wait(1).then((tx) => {
            setState(3);
            setInfo("Let's get your NFTs.");
          });
        } else {
          setState(3);
          setInfo("Let's get your NFTs.");
        }
      } else {
        setInfo("");
        setError("Insufficient BUSD Balance.");
        setState(1);
      }
    } catch (err) {
      console.log("err: ", err);
      setState(0);
    }
  }

  function viewLastPurchase() {
    let _nftArr;
    if (nfts.length == 0) {
      if (localStorage.getItem("lastPurchase") == null) {
        console.log("No Last Purchagse Data");
        return;
      } else {
        _nftArr = JSON.parse(localStorage.getItem("lastPurchase"));
        setNfts(_nftArr);
      }
    }
    setOpenApprove(false);
    setOpenThePrize(true);
    setShowItems(false);
    setState(1);
    setError("");
    setNumBox(3);
  }

  function buyPack(_num) {
    setError("");
    setNumBox(_num);
    setInfo("Check your BUSD Allowance");
    setOpenApprove(true);
    busdApproval(numBox * boxPrice);
  }

  return (
    <div className="flex flex-col w-full justifyitem-center items-center mt-20 select-none">
      <AddNetwork CCOIN={CCOIN} CCSX={CCSX} BUSD={BUSD} />

      <div className="sticky top-28  w-full z-50  text-black translate-x-52 hover:translate-x-14 transition-all duration-500">
        <Link to="/mystake">
          <div
            onClick={() => setPing(false)}
            className="absolute top-0 right-5 pr-10 flex flex-row text-2xl font-bold items-center bg-neutral-100 bg-opacity-70 backdrop-blur-xl hover:bg-opacity-90 border-2 border-black/70 hover:scale-105  transition-all rounded-xl p-3 drop-shadow-2xl cursor-pointer break-all "
          >
            <FaBell
              className={`absolute left-2 -mt-12 -ml-4 text-red-500 -rota -rotate-13 animate-ping ${
                ping ? "" : "hidden"
              }`}
            />
            <FaBell
              className={`absolute left-2 -mt-12 -ml-4 text-red-500 -rotate-13 ${
                ping ? "" : "hidden"
              }`}
            />
            <MdOutlineViewInAr className="mr-4" />
            Your Stake
          </div>
        </Link>
      </div>
      <RewardCountAPI />

      <div className="border-0 w-full md:w-5/6 grid grid-flow-row grid-cols-2 md:grid-cols-4 gap-2 p-5 mt-10 rounded-xl  bg-neutral-100 bg-opacity-70 backdrop-blur-xl transition-all">
        <div className="hidden md:block blur-sm absolute -top-4 md:right-4 text-3xl md:text-4xl self-center -mb-3 font-bold text-yellow-700 ">
          T r e a s u r e H u n t
        </div>
        <div className="hidden md:block absolute -top-5 md:right-5 text-3xl md:text-4xl self-center -mb-3 font-bold text-white ">
          T r e a s u r e H u n t
        </div>

        <div className="md:hidden -mt-10 col-span-2 text-3xl font-bold text-yellow-700 blur-sm">
          T r e a s u r e H u n t
        </div>
        <div className="md:hidden -mt-12 col-span-2 text-3xl font-bold text-white z-40">
          T r e a s u r e H u n t
        </div>

        {/*}
                <div className="w-full mb-28  grid grid-flow-row grid-cols-5 mt-10">
                    <CardDetail id={1} />
                    <CardDetail id={2} />
                    <CardDetail id={3} />
                    <CardDetail id={4} />
                    <CardDetail id={5} />

                    <CardDetail id={6} />
                    <CardDetail id={7} />
                    <CardDetail id={8} />
                    <CardDetail id={9} />
                    <CardDetail id={10} />
                </div> */}

        <div
          onClick={() => viewLastPurchase()}
          className="text-md text-gray-700 cursor-pointer items-center flex flex-row font-bold col-span-2 md:col-span-4 hover:scale-110 origin-left transition-all"
        >
          <BiPurchaseTag />
          View your Latest Purchase
        </div>
        <div
          onClick={() => {
            buyPack(1);
          }}
          className="col-span-1 transition-all duration-500 m-2 cursor-pointer hover:scale-110"
        >
          <div className="item-bg flex h-[350px] flex-col justify-center overflow-hidden items-center rounded-xl transition-all duration-200 ">
            <div className="h-[300px] overflow-hidden">
              <img
                className="px-1 transition-all duration-1000 hover:scale-110 "
                width={200}
                src={process.env.PUBLIC_URL + "/img/pack1.png"}
              />
            </div>
            <div className="relative translate-y-14 -mt-16 w-full bg-white  text-gray-500 p-4 justify-center text-center transition-all duration-200 hover:-translate-y-0">
              <span className="flex flex-row text-yellow-500 font-bold -mt-3">
                Crypterium Fantasy
              </span>
              <span className="flex flex-row text-black text-sm">
                Special NFT Items x 1
              </span>

              <div className="w-full flex flex-row justify-between my-3">
                <span>Price</span>
                <span className="flex flex-row text-black font-bold">
                  <img
                    className="scale-75"
                    src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                  />
                  150
                </span>
              </div>
              <div className="text-white w-full mt-2 border-[0px] p-2 bg-[#36300f] rounded-md cursor-pointer hover:bg-[#ffae00] transition-all">
                Buy Now
              </div>
            </div>
          </div>
        </div>

        {/*///////////////////// PACK 3 //////////////////////////// */}
        <div
          onClick={() => {
            buyPack(3);
          }}
          className="col-span-1 transition-all duration-500 m-2 cursor-pointer hover:scale-110"
        >
          <div className="item-bg flex h-[350px] flex-col justify-center overflow-hidden items-center rounded-xl transition-all duration-200 ">
            <div className="h-[300px] overflow-hidden">
              <img
                className="px-1 transition-all duration-1000 hover:scale-110 scale-105"
                width={200}
                src={process.env.PUBLIC_URL + "/img/pack3.png"}
              />
            </div>
            <div className="relative translate-y-14 -mt-16 w-full bg-white  text-gray-500 p-4 justify-center text-center transition-all duration-200 hover:-translate-y-0">
              <span className="flex flex-row text-yellow-500 font-bold -mt-3">
                Crypterium Fantasy
              </span>
              <span className="flex flex-row text-black text-sm">
                Special NFT Items x 3
              </span>

              <div className="w-full flex flex-row justify-between my-3">
                <span>Price</span>
                <span className="flex flex-row text-black font-bold">
                  <img
                    className="scale-75"
                    src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                  />
                  450
                </span>
              </div>
              <div className="text-white w-full mt-2 border-[0px] p-2 bg-[#36300f] rounded-md cursor-pointer hover:bg-[#ffae00] transition-all">
                Buy Now
              </div>
            </div>
          </div>
        </div>
        {/*///////////////////// PACK 5 //////////////////////////// */}

        <div
          onClick={() => {
            buyPack(5);
          }}
          className="col-span-1 transition-all duration-500 m-2 cursor-pointer hover:scale-110"
        >
          <div className="item-bg flex h-[350px] flex-col justify-center overflow-hidden items-center rounded-xl transition-all duration-200 ">
            <div className="bg-white/5 p-1 w-full rounded-sm text-xs text-yellow-400 flex flex-col justify-end items-center">
              <div>Rank S Guarantee</div>
              <div className="flex flex-row ">
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
              </div>
            </div>
            <div className="h-[300px] overflow-hidden">
              <img
                className="px-1 transition-all duration-1000 hover:scale-110 -mt-5 "
                width={200}
                src={process.env.PUBLIC_URL + "/img/pack5.png"}
              />
            </div>
            <div className="relative translate-y-14 -mt-16 w-full bg-white  text-gray-500 p-4 justify-center text-center transition-all duration-200 hover:-translate-y-0">
              <span className="flex flex-row text-yellow-500 font-bold -mt-3">
                Crypterium Fantasy
              </span>
              <span className="flex flex-row text-black text-sm">
                Special NFT Items x 5
              </span>

              <div className="w-full flex flex-row justify-between my-3">
                <span>Price</span>
                <span className="flex flex-row text-black font-bold">
                  <img
                    className="scale-75"
                    src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                  />
                  750
                </span>
              </div>
              <div className="text-white w-full mt-2 border-[0px] p-2 bg-[#36300f] rounded-md cursor-pointer hover:bg-[#ffae00] transition-all">
                Buy Now
              </div>
            </div>
          </div>
        </div>
        {/*///////////////////// PACK 10 //////////////////////////// */}
        <div
          onClick={() => {
            buyPack(10);
          }}
          className="col-span-1 transition-all duration-500 m-2 cursor-pointer hover:scale-110"
        >
          <div className="item-bg flex h-[350px] flex-col justify-center overflow-hidden items-center rounded-xl transition-all duration-200 ">
            <div className="bg-white/5 p-1 w-full rounded-sm text-xs text-yellow-400 flex flex-col justify-end items-center">
              <div>Rank NFT Guarantee</div>
              <div className="flex flex-row ">
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
              </div>
            </div>
            <div className="h-[300px] overflow-hidden">
              <img
                className="px-1 transition-all -mt-9 duration-1000 hover:scale-110 "
                width={200}
                src={process.env.PUBLIC_URL + "/img/pack10.png"}
              />
            </div>
            <div className="relative translate-y-14 -mt-16 w-full bg-white  text-gray-500 p-4 justify-center text-center transition-all duration-200 hover:-translate-y-0">
              <span className="flex flex-row text-yellow-500 font-bold -mt-3">
                Crypterium Fantasy
              </span>
              <span className="flex flex-row text-black text-sm">
                Special NFT Items x 10
              </span>

              <div className="w-full flex flex-row justify-between my-3">
                <span>Price</span>
                <span className="flex flex-row text-black font-bold">
                  <img
                    className="scale-75"
                    src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                  />
                  1,500
                </span>
              </div>
              <div className="text-white w-full mt-2 border-[0px] p-2 bg-[#36300f] rounded-md cursor-pointer hover:bg-[#ffae00] transition-all">
                Buy Now
              </div>
            </div>
          </div>
        </div>
      </div>

      <OrderHistory />
      {/*///////////////////// HIDDEN TEST FUNCTION ////////////////////////// */}
      <div
        onClick={() => {
          console.log(uniq(array2).arrId);
          console.log(uniq(array2).arrAmt);
        }}
        className="hidden text-md text-gray-700 cursor-pointer"
      >
        calArray
      </div>
      {/*<StakeAd />

                <Footer />*/}

      {/*///////////////////// //////////////////////////// */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openApprove && state != 0}
        className="flex flex-col"
      >
        <div className="bg-gray-900 flex flex-col justify-center items-center m-4 text-center text-gray-900 text-lg p-3 rounded-lg md:w-3/5 w-full display-linebreak">
          <div
            onClick={() => setState(0)}
            className={`absolute bg-black/30 w-full backdrop-blur-md backdrop-grayscale-50 z-50 items-center py-40 text-white text-3xl ${
              !!account ? "hidden" : null
            }`}
          >
            Please Connect Wallet
            <br />
            <br />
            <span className="text-sm cursor-pointer p-1 border-2 rounded-md hover:bg-black/30">
              Close
            </span>
          </div>

          <div className="w-full flex flex-row justify-end">
            <FaWindowClose
              fontSize={28}
              color="#cc0e00"
              className={`transition-all duration-100 drop-shadow-lg ${
                state == 1 || state == 3 || state == 5
                  ? "cursor-pointer hover:scale-125 "
                  : "grayscale opacity-10"
              }`}
              onClick={() => {
                setError("");
                state == 1 || state == 3 || state == 5
                  ? setState(0)
                  : setState(state);
              }}
            />
          </div>
          <div className="w-full flex flex-row justify-center">
            <div className="py-7 text-gray-300 text-3xl font-bold">
              {info}
              <span className="text-rose-500 font-bold">{error}</span>
            </div>
          </div>

          {state == 1 ? (
            <>
              <div
                id="coinSelect"
                className="w-full flex flex-row justify-center py-2"
              >
                <div className="md:w-1/3 w-2/3 rounded-lg mx-2 p-3 transition-all  bg-gray-700 text-gray-200 drop-shadow-md scale-105">
                  <span className="text-xs flex flex-row justify-end">
                    Your Balance
                  </span>
                  <span className="flex flex-row justify-between">
                    <span className="flex flex-row">
                      <img
                        className="px-1"
                        width={36}
                        src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                      />
                      BUSD
                    </span>
                    <AnimatedNumber
                      value={BUSDBalance}
                      formatValue={formatValue}
                      duration="500"
                    />
                  </span>
                </div>
              </div>
              <div
                onClick={() => {
                  setState(2);
                  setError("");
                  setInfo("Approve BUSD");
                  getBUSDApprove(numBox * boxPrice);
                }}
                className="w-2/3 bg-[#0059ff] text-white text-3xl font-bold p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
              >
                Ok, go!
              </div>
            </>
          ) : null}
          {state == 2 ? (
            <>
              <div className="relative w-2/3 bg-gray-400 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                <AiOutlineLoading3Quarters
                  color="inherit"
                  fontSize={24}
                  className="absolute left-1/4 mx-3 font-bold animate-spin"
                />
                <span className="animate-pulse text-3xl font-bold">
                  Waiting
                </span>
              </div>
            </>
          ) : null}

          {state == 3 ? (
            <>
              <img
                className="px-1 hover:scale-120 transition-all card shadow-white"
                width={200}
                src={process.env.PUBLIC_URL + "/img/pack" + numBox + ".png"}
              />
              <div
                onClick={() => {
                  setState(4);
                  setInfo("Please Confirm Metamask");
                  unBox(numBox);
                }}
                className="w-2/3 bg-[#0f63ff] flex flex-row text-white text-3xl text-center justify-center font-bold  p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
              >
                Let's Rock! - (
                <img
                  className="scale-75 mt-1"
                  src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                />
                {boxPrice * numBox})
              </div>
            </>
          ) : null}

          {state == 4 ? (
            <>
              {" "}
              <img
                className="px-1 hover:scale-120 transition-all card shadow-white"
                width={200}
                src={process.env.PUBLIC_URL + "/img/pack" + numBox + ".png"}
              />
              <div className="relative w-2/3 bg-gray-400 text-white p-2 m-2 pl-0 my-4 rounded-md shadow-lg items-center justify-center content-center flex flex-row">
                <AiOutlineLoading3Quarters
                  color="inherit"
                  fontSize={24}
                  className="absolute left-1/4 mx-3 font-bold animate-spin"
                />
                <span className="animate-pulse text-3xl font-bold">
                  Waiting
                </span>
              </div>
            </>
          ) : null}

          {state == 5 ? (
            <>
              {" "}
              <img
                className="px-1 hover:scale-120 transition-all card shadow-white"
                width={200}
                src={process.env.PUBLIC_URL + "/img/pack" + numBox + ".png"}
              />
              <div
                onClick={() => {
                  setOpenApprove(false);
                  setOpenThePrize(true);
                  setShowItems(false);
                }}
                className="w-2/3 bg-[#0f63ff] text-white text-3xl font-bold p-2 m-2 my-4 hover:scale-105 transition-all rounded-md shadow-lg items-center justify-center content-center flex flex-row cursor-pointer"
              >
                Let me see.
              </div>
            </>
          ) : null}

          {state == 6 ? (
            <>
              <div
                id="coinSelect"
                className="w-full flex flex-row justify-center py-2"
              >
                <div className="md:w-1/3 w-2/3 rounded-lg mx-2 p-3 transition-all  bg-gray-700 text-gray-200 drop-shadow-md scale-105">
                  <span className="text-xs flex flex-row justify-end">
                    Your Balance
                  </span>
                  <span className="flex flex-row justify-between">
                    <span className="flex flex-row">
                      <img
                        className="px-1"
                        width={36}
                        src={process.env.PUBLIC_URL + "/img/coins/busd.png"}
                      />
                      BUSD
                    </span>
                    <AnimatedNumber
                      value={BUSDBalance}
                      formatValue={formatValue}
                      duration="500"
                    />
                  </span>
                </div>
              </div>
              <div
                onClick={() => {
                  setInfo("");
                  setError("");
                  setState(0);
                }}
                className="w-2/3 bg-[#0059ff] text-white text-3xl font-bold p-2 m-2 my-4 rounded-md hover:scale-105 transition-all shadow-lg cursor-pointer"
              >
                Close
              </div>
            </>
          ) : null}

          <br />
        </div>
        <br />
      </Backdrop>

      {/*///////////////////// THE PRIZE //////////////////////////// */}
      <Backdrop
        //onClick={() => setOpenThePrize(false)}
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openThePrize && state != 0}
        className="flex flex-col"
      >
        <div className=" m-0 w-full h-screen overflow-hidden">
          <div className="w-full">
            <div
              onClick={() => setShowItems(true)}
              className={`relative overflow-hidden bg-black/70 align-middle content-center text-right backdrop-blur-2xl float-left w-1/2 h-screen z-50  cursor-pointer ${
                showItems
                  ? "-translate-x-full delay-500 transition-all duration-1000"
                  : ""
              }`}
            >
              <h1>Click to reveal&nbsp;</h1>
            </div>

            <div className="bg-[#100e17] absolute w-full h-full z-10 content-center justify-center items-center flex flex-col">
              <span className="text-gray-300 text-4xl font-bold my-9 -mt-9">
                Congratulations!, you've got...
              </span>
              <div
                className={`grid grid-flow-row gap-2 justify-center border-0 ${
                  nfts.length == 1
                    ? "grid-cols-1"
                    : nfts.length == 3
                    ? "grid-cols-3"
                    : "grid-cols-5"
                }`}
              >
                {nfts.map((x, i) => {
                  return <NFTCard key={i} id={x} />;
                })}
              </div>
              <div
                onClick={() => {
                  setShowItems(false);
                  setTimeout(5000, []);
                  setOpenThePrize(false);
                  setPing(true);
                }}
                className="cursor-pointer text-xl font-bold p-3 bg-white/10 text-gray-300 rounded-md mt-5 transition-all duration-100 hover:scale-105 hover:bg-orange-400"
              >
                Done
              </div>
              <div
                onClick={() => {
                  html2canvas(document.body, {
                    useCORS: true,
                    allowTaint: true,
                  }).then((canvas) => {
                    let a = document.createElement("a");
                    a.download = nfts + ".png";
                    a.href = canvas.toDataURL("image/png");
                    a.click(); // MAY NOT ALWAYS WORK!
                  });
                }}
                className="hidden cursor-pointer text-xl font-bold p-3 bg-white/10 text-gray-300 rounded-md mt-5 transition-all duration-100 hover:scale-105 hover:bg-orange-400"
              >
                {" "}
                Screen Shot
              </div>
            </div>

            <div
              onClick={() => setShowItems(true)}
              className={`relative   bg-black/70 backdrop-blur-2xl float-left w-1/2 h-screen z-50  cursor-pointer ${
                showItems
                  ? "translate-x-full delay-500 transition-all duration-1000"
                  : ""
              }`}
            >
              <h1>a special reward</h1>
              <div
                className={`transition-all duration-300 border-2 w-fit
                                    ${showItems ? "opacity-0 " : ""}
                                `}
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/loadingRandom.gif"}
                  width="1900px"
                  className="fixed top-1/4 -left-1/2  z-50 drop-shadow-lg transition-all"
                />
                {/*
                                <GiUnlocking
                                    size={120}
                                    className="fixed -mt-10 top-1/2 -left-12 z-50 hover:scale-105 drop-shadow-lg transition-all"
                        /> */}
              </div>
            </div>
          </div>
        </div>
      </Backdrop>
    </div>
  );
}

export default NFTSale;
